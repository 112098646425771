import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { RiDownloadLine } from 'react-icons/ri';
import { MdPhotoCamera } from 'react-icons/md';
import { BsCameraVideoFill } from 'react-icons/bs';

import api, { base, lang } from '../../services/api';

import './styles.css';

export const Galeria = ({posts}) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    getImages();
  }, []);

  const getImages = () => {
    let toImages = [];
    let limit = 8;

    posts.map(post => {
      post.pics.map(p => {
        if(toImages.length < limit) toImages.push(p);
      });
    });

    setImages(toImages);
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  return (
    <>
      <Row>
        <Col>
          <div className="boxFlex">
            <ul className="titleCategories">
                <li>Banco de imagens</li>
            </ul>
            <ul className="titleCategories titleLarger">
              <li><a style={{color: "#f15e22"}} href={`/images/Galeria`}>Ver mais</a></li>
            </ul>
          </div>
        </Col>
      </Row>
      <div>
        <Slider {...settings} className="slick-gallery">
          {images.map((i, index) => (
            <div key={index} className="sliderGallery">
              <div className="imgGallery">
                <img src={i.pic_v} alt={i.credits} />
              </div>
              <div className="contentGallery">
                <b>LEGENDA:</b>
                <p>{i.caption}</p>
                <div className="boxFlex">
                  <div className="item">
                    <b>CRÉDITOS:</b>
                    <p>{i.credits}</p>
                  </div>
                  <div className="item">
                    <a href={i.fullpic_h} target="_blank" download>
                      <RiDownloadLine />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default Galeria;