import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import './styles.css';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Galeria from '../../components/Galeria';
import Midias from '../../components/Midias';
import Revenues from '../../components/Revenues';
import NaMidia from '../../components/NaMidia';
import Agenda from '../../components/Agenda';
import Imprensa from '../../components/Imprensa';
import News from '../../components/News';
import NewsBigger from '../../components/NewsBigger';
import NewsVertical from '../../components/NewsVertical';
import Slider from '../../components/Slider';

import api from '../../services/api';

export const Home = () => {
  const [headers, setHeaders] = useState([]);
  const [first, setFirst] = useState([]);
  const [social, setSocial] = useState([]);
  const [revenues, setRevenues] = useState([]);
  const [slices, setSlices] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [slider, setSlider] = useState([]);
  const [multimidia, setMultimidia] = useState([]);
  const [second, setSecond] = useState([]);
  const [media, setMedia] = useState([]);
  const [socialmedias, setSocialmedias] = useState([]);
  const [calendar, setCalendar] = useState([]);

  useEffect(() => {
    getNews();
  }, []);

  const getNews = () => {
    api.get('/getposts.php?fullurl&iframe&nolinks').then(res => {
      let toHeaders = res.data.headers;
      let toSlices = res.data.slices;

      let toSlider = [];
      let toFirst = [];
      let toMultimidia = [];
      let toSecond = [];
      let toGallery = [];
      let toMedia = [];
      let toCalendar = [];
      let toSocialmedias = [];

      res.data.posts.map(post => {
        if(post.firstfold === 1){
          toSlider.push(post);
          return;
        }

        if(post.slice === "Calendario"){
          toCalendar.push(post);
          return;
        }

        if(post.slice === "Na Mídia"){
          toMedia.push(post);
          return;
        }

        if(post.slice === "Multimídia"){
          toMultimidia.push(post);
          return;
        }

        if(post.slice === "Galeria"){
          toGallery.push(post);
          return;
        }

        if(post.slice === "Mídias Sociais"){
          toSocialmedias.push(post);
          return;
        }

        if(post.slice !== "Notícias - Produtos" && post.slice !== "Notícias - Campanhas") return;

        if(toFirst.length < 4){
          toFirst.push(post);
          return;
        }

        if(toSecond.length < 4){
          toSecond.push(post);
          return;
        }
        
      });

      setHeaders(toHeaders);
      setSlices(toSlices);

      setSlider(toSlider);
      setFirst(toFirst);
      setMultimidia(toMultimidia);
      setSecond(toSecond);
      setGallery(toGallery);
      setMedia(toMedia);
      setCalendar(toCalendar);
      setSocialmedias(toSocialmedias);

    });
  }

  return (
    <>
      {headers.length > 0 &&
        <Header headers={headers} />
      }
      <div className="container">
        <Row>
          <Col lg={8}>
            {slider.length > 0 &&
              <Slider posts={slider} />
            }
            {multimidia.length > 0 &&
              <NewsBigger
                post={multimidia[0]}
                mult={multimidia}
                hasBorder={true}
                hasImage={true}
                title={multimidia[0].title}
                desc={multimidia[0].description}
                slug={multimidia[0].slug}
                cards={multimidia[0].cards}
                pics={multimidia[0].pics}
                contents={multimidia[0].contents}
              />
            }
          </Col>
          <Col lg={4}>
            <Row>
              <Col>
                <ul className="titleCategories" style={{ marginTop: 0, marginBottom: 15}}>
                    <li>Destaques</li>
                </ul>
              </Col>
            </Row>
            {first.map((post, index) => (
              <News 
                post={post}
                key={index}
                hasBorder={true}
                title={post.title}
                desc={post.description}
                slug={post.slug}
                cards={post.cards}
              />
            ))}
          </Col>
        </Row>
        {calendar.length > 0 &&
          <Row>
            <Col sm={12}>
              <Agenda posts={calendar} />
            </Col>
          </Row>
        }
        <Row>
          {second.length > 0 &&
            <Col sm={12}>
              <Row>
                <Col>
                  <div className="boxFlex">
                    <ul className="titleCategories">
                        <li>Outras notícias</li>
                    </ul>
                    <ul className="titleCategories titleLarger">
                      <li><a href={`/slice/Notícias`} style={{color: "#f15e22"}}>VER TODAS</a></li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <div className="otherNewsBox">
                <Row>
                  {second.map((post, index) => (
                    <Col sm={3} key={index}>
                      <NewsVertical
                        post={post}
                        hasBorder={true}
                        title={post.title}
                        slug={post.slug}
                        cards={post.cards}
                        pics={post.pics}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          }
        </Row>
        {media.length > 0 &&
          <NaMidia posts={media} />
        }
        {socialmedias.length > 0 &&
          <Midias posts={socialmedias} />
        }
        {gallery.length > 0 &&
          <Galeria posts={gallery} />
        }
      </div>
      <Footer />
    </>
  );
}

export default Home;