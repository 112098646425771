import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { FiInstagram } from 'react-icons/fi';
import { FaFacebookF } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';

import './styles.css';

export default class Footer extends React.Component {
    render() {
      return (
        <footer>
            <div className="container">
                <p>© COPYRIGHT 2021. SEARA.</p>
                <p><b>MEDIA ROOM</b></p>
            </div>
        </footer>
      );
    }
}  
