import React, { useEffect, useState } from 'react';

import { BiSearch } from 'react-icons/bi';

import './styles.css';

import logo from '../../assets/images/logo.png';

import ModalFilter from '../../components/ModalFilter';
import ModalSearch from '../ModalSearch';
import { Link } from 'react-router-dom';
import ModalContents from '../ModalContents';

import api from '../../services/api';

export const Header = ({ headers }) => {
  const [menu, setMenu] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showModalSearch, setShowModalSearch] = useState(false);
  const [showModalContents, setShowModalContents] = useState(false);

  const [current, setCurrent] = useState('');
  const [items, setItems] = useState([]);
  const [type, setType] = useState('');
  const [contents, setContents] = useState('');
  const [contactContents, setContactContents] = useState('');

  useEffect(() => {
    getContactContents();
    document.addEventListener('scroll', handleScroll);

    return function cleanup() {
      document.removeEventListener('scroll', handleScroll)
    };
  }, []);

  const handleScroll = () => {
    let header = document.querySelector('header');
    let hasFixed = header.classList.contains('fixed');

    if(window.scrollY > 120){
      if(!hasFixed){
        header.classList.add('fixed');
      }
    } else if(hasFixed){
      header.classList.remove('fixed');
    }
  }

  const handleShowMenu = () => {
    let b = document.querySelector('body');
    if(!menu){
      b.style.overflow = 'hidden';
    } else {
      b.style.overflow = 'auto';
    }
    setMenu(!menu);
  }

  const handleShowSearch = e => {
    e.preventDefault();
    setShowModalSearch(true);
  }

  const getContactContents = () => {
    api.get('/getposts.php?slice=contato').then(res => {
      console.log(res.data, 'posts');
      if(res.data.posts.length > 0){
        setContactContents(res.data.posts[0].contents);
      } else {
        setContactContents('');
      }
    });
  }

  const handleOpenMenuItem = (e, header) => {
    e.preventDefault();
    const { route } = header;
    
    if(route === 'all') window.location.href=`/slice/Notícias`;

    if(route === 'contact'){
      setCurrent(header.name);
      setContents(contactContents);
      setShowModalContents(true);
    };

    if(route === 'register'){
      setCurrent(header.name);
      setContents('<p>Aguardando conteúdo.</p>');
      setShowModalContents(true);
    };

    if(route === 'tag' && 'tags' in header){
      setCurrent(header.name);
      setItems(header.tags);
      setType(route);
      setShowModalFilter(true);
    } else if(route === 'tag'){
      window.location.href=`/${route}/${header.name}`;
    }

    if(route === 'card' && 'cards' in header){
      setCurrent(header.name);
      setItems(header.cards);
      setType(route);
      setShowModalFilter(true);
    } else if(route === 'card'){
      window.location.href=`/${route}/${header.name}`;
    }

    if(route === 'slice' && 'slices' in header){
      setCurrent(header.name);
      setItems(header.slices);
      setType(route);
      setShowModalFilter(true);
    } else if(route === 'slice'){
      window.location.href=`/${route}/${header.name}`;
    }
  }

  return (
    <>
      {showModalContents &&
        <ModalContents
          title={current}
          contents={contents}
          onClose={() => setShowModalContents(false)}
        />
      }
      {showModalFilter &&
        <ModalFilter
          title={current}
          items={items}
          type={type}
          onClose={() => setShowModalFilter(false)}
        />
      }
      {showModalSearch &&
        <ModalSearch
          title={'Faça sua busca'}
          onClose={() => setShowModalSearch(false)}
        />
      }

      <header>
        <div id="headerBox" className="container">
          <button className="openMenu" onClick={handleShowMenu}>
            <div className="rowToggle"></div>
            <div className="rowToggle"></div>
            <div className="rowToggle"></div>
          </button>

          <a href="https://www.seara.com.br" target="_blank" >
            <img src={logo} alt="JBS" id="logo"/>
          </a>

          <div className="header-col-center">
            <Link to={'/'}>
              <h4>Sala de imprensa</h4>
            </Link>
          </div>

          <ul id="mainMenu" className={menu ? 'show' : 'hide'}>
            {headers.map((header, index) => (
              <li key={index}><a href="#" onClick={e => handleOpenMenuItem(e, header)}>{header.name}</a></li>
            ))}
            <li><a href="#" onClick={handleShowSearch}><BiSearch style={{ fontSize: 20}} /></a></li>
          </ul>
        </div>
      </header>
    </>
  );
}

export default Header;