export const lineBreak = text => {
    let res = text.replaceAll('[#]', `<br /><br />`);
    return res;
}

export const convertDateToMonth = (date) => {
    let currentYear = date.substr(0, 4);
    let currentMonth = date.substr(5, 2);
    currentMonth = parseInt(currentMonth) - 1;

    let months = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
    ];

    return `${months[currentMonth]} de ${currentYear}`;
}

export const convertDateToShortMonth = (date) => {
    let currentYear = date.substr(0, 4);
    let currentMonth = date.substr(5, 2);
    currentMonth = parseInt(currentMonth) - 1;

    let months = [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
    ];

    return `${months[currentMonth]} de ${currentYear}`;
}

export const orderArrByDate = arr => {
    return arr.sort(compare);
}

export const compare = (a, b) => {
    return a.data > b.data;
}