import React from 'react';
import { GrFormClose } from 'react-icons/gr';
import { Col, Row } from 'react-bootstrap';

import './styles.css';

export const ModalFilter = (props) => {
    const handleOverlayClose = (e) => {
        if(e.target === e.currentTarget) {
            props.onClose();
        }
    }

    return (
        <div className="modal-filter" onClick={handleOverlayClose}>
            <div className="modal-filter-content">
                <div className="modal-filter-header">
                    <h3>{props.title}</h3>
                    <button className="btn-close" onClick={() => props.onClose()}>
                        <GrFormClose />
                    </button>
                </div>
                <div className="modal-filter-body">
                    {props.items.length > 0 &&
                        <Row>
                            {props.items.map(i => (
                                <Col sm={4} xs={12}>
                                    <a href={`/${props.type}/${i}`}>{i}</a>
                                </Col>
                            ))}
                        </Row>
                    }
                    {props.items.length === 0 &&
                        <p>Nenhuma item nesse menu.</p>
                    }
                </div>
            </div>
        </div>
    );
}

export default ModalFilter;