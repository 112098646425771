import React from 'react';
import moment from 'moment';

import { Col, Row } from 'react-bootstrap';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import News from '../../components/News';

import './styles.css';

import api, { lang, base } from '../../services/api';

export default class SingleNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      headers: [],
      slices: [],
      news: {},
      related: []
    };
  }

  componentDidMount() {
    this.getNews();
  }

  getNews = () => {
    // let form = new FormData();

    // form.append('ag', ag);
    // form.append('lang', lang);
    // form.append('search', this.props.match.params.slug);
    
    // api.post('/getPressRoomPost.php', form).then(res => {
    //   this.setState({ news: res.data }, () => this.getRelated());
    // });

    api.get(`${base}/getposts.php?fullurl&slug=${this.props.match.params.slug}`).then(res => {
      console.log(res);
      let script = res.data.posts[0]?.script;
      if(script) eval(script);
      this.setState({
        news: res.data.posts.length > 0 ? res.data.posts[0] : {},
        headers: res.data.headers,
        slices: res.data.slices,
        loading: false
      }, () => this.getRelated());
    });
  }

  getRelated = () => {
    // let form = new FormData();

    // form.append('ag', ag);
    // form.append('lang', lang);
    // form.append('start', 0);
    // form.append('end', 5);
    
    // // form.append('card', this.state.news.extra.card);
    
    // api.post('/getPressRoomPostRange.php', form).then(res => {
    //   this.setState({ related: res.data });
    // });

    let slice = Object.keys(this.state.news).length > 0 ? this.state.news.slice : null;

    if(slice){
      api.get(`${base}/getposts${lang === 'br' ? '' : '_en'}.php?slice=${slice}&start=0&end=3`).then(res => {
        this.setState({
          related: res.data.posts
        });
      });
    }
  }

  render() {
    return (
      <>
        {!this.state.loading &&
          <>
            <Header headers={this.state.headers} />
            <div className="container">
              <div className="content">
                {this.state.news.pics.length > 0 &&
                  <img src={this.state.news.pics[0].pic_h} alt={this.state.news.pics[0].caption} className="newsSingleImage" />
                }
                <div className="boxTopNotice">
                  {this.state.news.shortdate &&
                    <div className="dateNotice">
                      <span>{this.state.news.shortdate}</span>
                    </div>
                  }
                </div>
                <h1 className="newsSingleTitle">{this.state.news.title}</h1>
                <h2 className="newsSingleSubtitle">{this.state.news.description}</h2>
                
                <div className="newsSingleContent" dangerouslySetInnerHTML={{__html: this.state.news.contents}} />
                
                {this.state.news.tags &&
                  <>
                    {this.state.news.tags.length > 0 &&
                      <div className="singleNewsTags">
                        {this.state.news.tags.map(tag => (
                          <a href={`/tag/${tag}`}><span>{tag}</span></a>
                        ))}
                      </div>
                    }
                  </>
                }                
                
                {this.state.related.length > 0 &&
                  <h3 className="titleMoreNews">{lang === 'br' ? 'Mais notícias' : 'More news'}</h3>
                }
                

                <Row>
                  {this.state.related.map((r,index) => (
                    <Col key={index} sm={6} xs={12}>
                      <News
                        post={r}
                        title={r.title} 
                        desc={r.description} 
                        tags={r.tags}
                        slug={r.slug}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
            <Footer />
          </>
        }
      </>
    );
  }
}  