import React from 'react';

import './styles.css';
import Image from '../Image';
import { lineBreak } from '../../useful/helpers';

export const NewsVertical = (props) => {
  return (
    <div className="newsVertical">
      {props.pics.length > 0 &&
        <a href={`/noticia/${props.slug}`}>
          <Image post={props.post} className="newsVerticalImg" />
        </a>
      }
      <div className="newsVerticalContent">
        <div className="infoBoxVertical">
          <ul>
            {props.tags &&
              <>
                {props.tags.length > 0 &&
                  <li><button onClick={() => window.location.href=`/tag/${props.tags[0]}`}>{props.tags[0]}</button></li>
                }
              </>
            }

            {props.cards &&
              <>
                {props.cards.length > 0 &&
                  <li><button onClick={() => window.location.href=`/card/${props.cards[0]}`}>{props.cards[0]}</button></li>
                }
              </>
            }
          </ul>
        </div>

        <a href={`/noticia/${props.slug}`}>
          <h2 className="newsVerticalTitle"><span>{props.title}</span></h2>
        </a>
        {props.desc &&
          <a href={`/noticia/${props.slug}`}>
            <h3 className="newsVerticalDesc" dangerouslySetInnerHTML={{ __html: lineBreak(props.desc)}} />
          </a>
        }
      </div>
    </div>
  );
}

export default NewsVertical;