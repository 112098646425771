import React from 'react';
import { Col, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import News from '../../components/News';
import NewsMidia from '../../components/NewsMidia';

import './styles.css';

import api, { lang } from '../../services/api';

export default class Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      getting: false,
      page: 0,
      news: [],
      prevY: 0,
      hasMore: true,
      headers: [],
      slices: []
    };
  }

  componentDidMount() {
    this.getNews();
  }

  getNews = () => {
    const perPage = 11;
    
    if(this.state.getting) return;
    this.setState({ getting: true });
    // let form = new FormData();

    let start = (perPage * this.state.page) + this.state.page;
    let end = start + perPage;

    let params = {
      // slice: '%94noticias',
      start,
      end
    };
    let value = this.props.match.params.value;
    // params[this.props.match.params.param] = value === 'Notícias' ? 'notic*' : value;

    api.get(`/getposts${lang === 'br' ? '' : '_en'}.php?slice=not%C3%ADcias%20-`, { params }).then(res => {
      let hasMore = res.data.posts.length < perPage ? false : true;
      this.setState({
        loading: false,
        getting: false,
        news: [...this.state.news, ...res.data.posts],
        headers: res.data.headers,
        slices: res.data.slices,
        hasMore,
        page: this.state.page + 1 
      });
    });
  }

  render() {
    return (
      <>
        {!this.state.loading &&
          <>
            <Header headers={this.state.headers} />
            <div className="container">
              <h1 className="pageTitle">{this.props.match.params.value}</h1>
              <InfiniteScroll
                dataLength={this.state.news.length}
                next={this.getNews}
                hasMore={this.state.hasMore}
                // loader={<p><b>{lang === 'br' ? 'Carregando...' : 'Loading...'}</b></p>}
                refreshFunction={this.getNews}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
              >
                <Row>
                  {this.state.news.map((n,index) => (
                    <Col key={index} sm={6} xs={12}>
                      {n.slice === "Na Mídia" &&
                        <NewsMidia 
                          post={n}
                          title={n.title}
                          desc={n.description}
                          date={n.shortdate}
                          links={n.links}
                          pics={n.pics}
                          hasLogoR7={true}
                        />
                      }
                      {n.slice !== "Na Mídia" &&
                        <News
                          post={n}
                          date={n.shortdate} 
                          title={n.title} 
                          desc={n.description} 
                          tags={n.tags}
                          slug={n.slug} 
                        />
                      }
                    </Col>
                  ))}
                </Row>
              </InfiniteScroll>
            </div>
            <Footer />
          </>
        }
      </>
    );
  }
}