import React, { useState } from 'react';
import { GrFormClose } from 'react-icons/gr';

export const ModalContents = (props) => {

    const handleOverlayClose = (e) => {
        if(e.target === e.currentTarget) {
            props.onClose();
        }
    }

    return (
        <div className="modal-filter" onClick={handleOverlayClose}>
            <div className="modal-filter-content">
                <div className="modal-filter-header">
                    <h3>{props.title}</h3>
                    <button className="btn-close" onClick={() => props.onClose()}>
                        <GrFormClose />
                    </button>
                </div>
                <div className="modal-filter-body">
                    <p dangerouslySetInnerHTML={{__html: props.contents}} />
                </div>
            </div>
        </div>
    );
}

export default ModalContents;