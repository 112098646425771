import React, { useEffect, useRef } from 'react';

export const Image = ({post, type, className}) => {
    const el = useRef();

    useEffect(() => {
        setStyle();    
    });

    const setStyle = () => {
        el.current.setAttribute('style', getStyle());
    }

    const getFirstPic = () => {
        if(post.pics){
            if(post.pics.length > 0){
                return type === 'h' ? post.pics[0].pic_h : post.pics[0].pic_v;
            }
        }
    }

    const getCaption = () => {
        if(post.pics){
            if(post.pics.length > 0){
                return post.pics[0].caption;
            }
        }
    }

    const getStyle = () => {
        if(post.pics){
            if(post.pics.length > 0){
                let style = post.pics[0].style;
                if(style){
                    return style;
                }
            }
        }

        return '';
    }

    return (
        <img loading="lazy" ref={el} src={`${getFirstPic()}`} alt={getCaption()} className={className} />
    );
}

export default Image;