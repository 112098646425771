import React from 'react';
import { GrFormClose } from 'react-icons/gr';
import { Col, Row } from 'react-bootstrap';

import { convertDateToShortMonth } from '../../useful/helpers';

export const ModalFilter = (props) => {
    const handleOverlayClose = (e) => {
        if(e.target === e.currentTarget) {
            props.onClose();
        }
    }

    const handleSelectItem = (e, item) => {
        e.preventDefault();
        props.onChange(item);
        props.onClose();
    }

    return (
        <div className="modal-filter" onClick={handleOverlayClose}>
            <div className="modal-filter-content">
                <div className="modal-filter-header">
                    <h3>{props.title}</h3>
                    <button className="btn-close" onClick={() => props.onClose()}>
                        <GrFormClose />
                    </button>
                </div>
                <div className="modal-filter-body">
                    {props.months.length > 0 &&
                        <Row>
                            {props.months.map(month => (
                                <Col sm={4} xs={12}>
                                    <a href='#' onClick={(e) => handleSelectItem(e, month)}>
                                        {convertDateToShortMonth(month)}
                                    </a>
                                </Col>
                            ))}
                        </Row>
                    }
                    {props.months.length === 0 &&
                        <p>Nenhuma data com evento</p>
                    }
                </div>
            </div>
        </div>
    );
}

export default ModalFilter;