import React from 'react';

import { Col, Row } from 'react-bootstrap';

import NewsMidia from '../NewsMidia';

import './styles.css';

export const NaMidia = ({posts}) => {

  posts = posts.slice(0,6);
  
  return (
    <div id="inMidia">
      <Row>
          <Col>
            <div className="boxFlex">
              <ul className="titleCategories">
                  <li>Na Mídia</li>
              </ul>
              <ul className="titleCategories titleLarger">
                <li><a href={`/slice/Na Mídia`} style={{color: "#f15e22"}}>VER TODAS</a></li>
              </ul>
            </div>
          </Col>
      </Row>
      <Row>
        {posts.map((post, index) => (
          <Col key={index} md={4}>
            <NewsMidia 
              title={post.title}
              desc={post.description}
              links={post.links}
              favicon={post.favicon}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default NaMidia;