import React, { useState } from 'react';
import { GrFormClose } from 'react-icons/gr';
import { BiSearch } from 'react-icons/bi';
import { Col, Row } from 'react-bootstrap';

import { convertDateToShortMonth } from '../../useful/helpers';

import './styles.css';

export const ModalSearch = (props) => {
    const [term, setTerm] = useState('');

    const handleOverlayClose = (e) => {
        if(e.target === e.currentTarget) {
            props.onClose();
        }
    }

    const handleSearch = () => {
        window.location.href = `/search/${term}`;
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    }

    return (
        <div className="modal-filter" onClick={handleOverlayClose}>
            <div className="modal-filter-content">
                <div className="modal-filter-header">
                    <h3>{props.title}</h3>
                    <button className="btn-close" onClick={() => props.onClose()}>
                        <GrFormClose />
                    </button>
                </div>
                <div className="modal-filter-body">
                    <div className="box-search">
                        <input onKeyDown={handleKeyDown} onChange={e => setTerm(e.target.value)} value={term} placeholder="Digite sua busca aqui" />
                        <button type="button" onClick={handleSearch}><BiSearch /></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalSearch;