import React from 'react';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import News from '../News';
import Image from '../Image';

import { BsChevronLeft } from 'react-icons/bs';
import { BsChevronRight } from 'react-icons/bs';

import './styles.css';

export const SliderHome = (props) => {
  
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    arrows: true,
    prevArrow: <BsChevronLeft />,
    nextArrow: <BsChevronRight />
  };

  return (
    <Slider {...settings} className="sliderRight">
      {props.posts.map((post, index) => (
        <div key={index} className="contentSlider">
          <div className="boxSlider">
            <a href={`/noticia/${post.slug}`}>
              <Image post={post} />
            </a>
          </div>
          <div className="newsSlider">
            <News 
              post={post}
              featured={true}
              hasBorder={true}
              title={post.title}
              desc={post.description}
              slug={post.slug}
              cards={post.cards} />
          </div>
        </div>
      ))}
    </Slider>
  );
}

export default SliderHome;