import React from 'react';

import './styles.css';

import { RiShareLine } from 'react-icons/ri';
import Image from '../Image';
import last from '../../assets/images/last.jpg';
import r7 from '../../assets/images/r7.png';
import g1 from '../../assets/images/g1.png';

import { lineBreak } from '../../useful/helpers';

export const NewsMidia = (props) => {
  let link = '#';

  if(props.links.length > 0){
    link =  props.links[0].link;
  }

  return (
    <div className="newsBox">
      <div className={`borderLeft ${props?.hasBorder ? '' : 'hide'}`}></div>
      {props.pics &&
        <>
          {props.pics.length > 0 &&
            <Image post={props.post} className="newsImg" />
          }
        </>
      }
      <div className="newsContent hasImage">
        <a href={link} target="_blank">
          <h2 className="newsTitle">{props.title}</h2>
        </a>
        {props.desc &&
          <a href={link} target="_blank">
            <h3 className="newsDesc" dangerouslySetInnerHTML={{ __html: lineBreak(props.desc)}} />
          </a>
        }
        <div className="infoBox">
          <ul>
          {props.tags &&
              <>
                {props.tags.length > 0 &&
                  <li><button onClick={() => window.location.href=`/tag/${props.tags[0]}`}>{props.tags[0]}</button></li>
                }
              </>
            }
            {props.favicon && props.favicon.length > 0 &&
              <li><a href={link}><img src={props.favicon[0]} alt={props.title} className="logoMidia" /></a></li>
            }
            {props.date &&
              <li><p>{props.date}</p></li>
            }
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NewsMidia;