import React from 'react';

import { Col, Row } from 'react-bootstrap';

import { BsChevronDown } from 'react-icons/bs';

import steak from '../../assets/images/steak.jpg';

import './styles.css';

import { base } from '../../services/api';

export default class Revenues extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      current: 0,
    };
  }

  handleNext = () => {
    let current = this.state.current;
    let max = this.props.revenues.length - 1;
    let next = this.state.current < max ? current + 1 : current;
    
    this.setState({ current: next });
  }

  handlePrev = () => {
    let current = this.state.current;
    let min = 0;
    let next = this.state.current !== min ? current - 1 : current;

    this.setState({ current: next });
  }

  render() {
    return (
      <div id="revenues">
        <Row>
            <Col>
              <div className="boxFlex">
                <ul className="titleCategories">
                    <li>{this.props.slices[4].name}</li>
                </ul>
                <ul className="titleCategories titleLarger">
                  <li><a href={`/slice/${this.props.slices[4].name}`} style={{color: "#f15e22"}}>VER TODAS</a></li>
                </ul>
              </div>
            </Col>
        </Row>
        {this.props.revenues.length > 0 &&
          <Row>
              <Col>
                  <div className="boxRevenue">
                    <Row>
                      {this.props.revenues[this.state.current].pics.length > 0 &&
                        <Col sm={5}>
                          <img 
                            src={`${base}/${this.props.revenues[this.state.current].pics[0].pic_h} `}
                            alt={this.props.revenues[this.state.current].title}
                          />
                        </Col>
                      }
                      <Col sm={this.props.revenues[this.state.current].pics.length > 0 ? 7 : 12}>
                        <div className="aboutRevenue">
                          <h2 className="titleRevenue">{this.props.revenues[this.state.current].title}</h2>
                          <div dangerouslySetInnerHTML={{__html: this.props.revenues[this.state.current].contents }} />
                        </div>
                      </Col>
                    </Row>
                  </div>
              </Col>
          </Row>
        }
        {this.props.revenues.length > 0 &&
          <Row>
            <Col>
              <div id="btns">
                <button 
                  className={`btnsRevenue ${this.state.current === 0 ? 'disabled' : ''}`}
                  id="btnPrev" 
                  onClick={() => this.handlePrev()}
                >
                  Anterior
                </button>

                <button
                  className={`btnsRevenue ${this.state.current === this.props.revenues.length - 1 ? 'disabled' : ''}`}
                  id="btnNext"
                  onClick={() => this.handleNext()}
                >
                  Próxima receita
                </button>
              </div>
            </Col>
          </Row>
        }
      </div>
    );
  }
}  