import React, { useState, useEffect } from 'react';

import { Col, Row } from 'react-bootstrap';

import Slider from "react-slick";


import { GoCalendar } from 'react-icons/go';

import moment from "moment";
import 'moment/locale/pt-br';

import './styles.css';

import { convertDateToShortMonth, orderArrByDate } from '../../useful/helpers';
import ModalFilter from '../ModalCalendar';

export const Agenda = ({posts}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState( moment(new Date()).format('YYYY-MM') );
  const [events, setEvents] = useState([]);
  const [months, setMonths] = useState([]);

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 2000,
    arrows: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      }
    ]
  };

  useEffect(() => {
    getEvents();
  }, [,date]);

  const getEvents = () => {
    let toEvents = [];
    let toMonths = [];

    let currentYear = date.substr(0, 4);
    let currentMonth = date.substr(5, 2);

    posts.map(p => {
      let eventMonth = p.title.substr(3, 2);
      let eventYear = p.title.substr(6, 4);
      if(eventYear === currentYear && eventMonth === currentMonth){
        toEvents.push(p);
      };
      let eventToMonth = `${eventYear}-${eventMonth}`;
      if(!toMonths.includes(eventToMonth)) toMonths.push(eventToMonth);
    });

    toMonths = orderArrByDate(toMonths);
    
    setEvents(toEvents);
    setMonths(toMonths);
  }

  const handleShowCalendar = () => {
    setShowCalendar(!showCalendar);
  }

  const handleChangeDate = date => {
    setDate(date);
  }

  return (
    <>
      {showCalendar &&
        <ModalFilter title="Escolha um mês" months={months} onChange={date => handleChangeDate(date)} onClose={() => setShowCalendar(false)} />
      }
      <Row>
        <Col>
          <ul className="titleCategories">
              <li>Agenda</li>
          </ul>
        </Col>
      </Row>
      <div className="contentCalendar">
        <div className="dateSchedule">
          <button
            onClick={() => handleChangeDate(moment(date, 'YYYY-MM').subtract(1, 'M').format('YYYY-MM'))}
          >
            {convertDateToShortMonth( moment(date, 'YYYY-MM').subtract(1, 'M').format('YYYY-MM') )}
          </button>

          <button className="active">{convertDateToShortMonth(date)}</button>

          <button
            onClick={() => handleChangeDate(moment(date, 'YYYY-MM').add(1, 'M').format('YYYY-MM'))}
          >
            {convertDateToShortMonth( moment(date, 'YYYY-MM').add(1, 'M').format('YYYY-MM') )}
          </button>
          <button className="showCalendar" onClick={handleShowCalendar}><GoCalendar />VER OUTRA DATA</button>
        </div>
        <div className="contentEvents">
          {events.length === 0 &&
            <p className="noEvents">Nenhum evento na data selecionada</p>
          }
          {events.length > 0 &&
            <Slider {...settings}>
              {events.map((event,index) => (
                <div key={index}  className="boxSchedule">
                  <p className="hour">{event.title}</p>
                  <p className="subject">{event.description}</p>
                  {/* <p dangerouslySetInnerHTML={{__html: event.contents}} /> */}
                  {/* <p className="place">Estúdio Seara</p>
                  <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis malesuada tellus leo, a scelerisque libero mattis a. Pellentesque ornare turpis at tristique cursus. Sed scelerisque, libero quis porttitor lobortis, arcu eros dictum nulla, eget auctor arcu lorem sed nisi.</p> */}
                </div>
              ))}
            </Slider>
          }
        </div>
      </div>
    </>
  );
}

export default Agenda;