import React from 'react';

import { Col, Row } from 'react-bootstrap';

import imprensa from '../../assets/images/imprensa.jpg';

import { GoCalendar } from 'react-icons/go';

import './styles.css';

export default class Imprensa extends React.Component {
  render() {
    return (
      <>
      <Row>
        <Col>
          <ul className="titleCategories">
              <li>Ferramenta de Imprensa</li>
          </ul>
        </Col>
      </Row>
      <img src={imprensa} className="imgImprensa" alt="Ferramenta de Imprensa" />
      </>
    );
    }
}  