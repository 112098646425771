import React from 'react';

import { Col, Row } from 'react-bootstrap';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { FiInstagram } from 'react-icons/fi';
import { FaFacebookF } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import Image from '../Image';
import galeria from '../../assets/images/galeria.jpg';

import api from '../../services/api';

import './styles.css';

const Midias = ({posts}) => {
  const medias = {
    ig: {
      url: 'https://www.instagram.com/searabrasil',
      name: 'searabrasil',
      icon: <FiInstagram />
    },
    fb: {
      url: 'https://pt-br.facebook.com/SearaBrasil',
      name: 'SearaBrasil',
      icon: <FaFacebookF />
    },
    tw: {
      url: 'https://twitter.com/seara_brasil',
      name: 'seara_brasil',
      icon: <FaTwitter />
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const MediaPost = ({post}) => {
    const media = post.cards.length > 0 ? post.cards[0] : null;
    const link = post.links.length > 0 ? post.links[0].link : '#';

    return (
      <div className="sliderGallery">
        <div className="imgGallery">
          <a href={link} target="_blank">
            <Image post={post} />
          </a>
        </div>
        <div className="contentGallery contentPost">
          {media != null &&
          <div className="boxFlex">
            <div className="item">
              <a className="media-link" href={medias[media].url}>
                @{medias[media].name}
              </a>
            </div>
            <div className="item">
              <a href={link} target="_blank">{medias[media].icon}</a>
            </div>
          </div>
          }
          <a href={link} className="media-link" target="_blank">
            <p className="postText">
              {post.description}
            </p>
          </a>
          {/* <p dangerouslySetInnerHTML={{__html: post.contents}} /> */}
        </div>
      </div>
    );
  }

  return (
    <>
      <Row>
        <Col>
          <div className="boxFlex">
            <ul className="titleCategories">
                <li>Mídias sociais</li>
            </ul>
            <ul className="titleCategories titleLarger">
                <li id="titleMidias">NOSSAS REDES SOCIAIS</li>
                <li><a href={medias.ig.url} target="_blank"><FiInstagram /></a></li>
                <li><a href={medias.fb.url} target="_blank"><FaFacebookF /></a></li>
                <li><a href={medias.tw.url} target="_blank"><FaTwitter /></a></li>
            </ul>
          </div>
        </Col>
      </Row>
      <div>
        <Slider {...settings}>
          {posts.map((post, index) => (
            <MediaPost key={index} post={post} />
          ))}
        </Slider>
      </div>
    </>
  );
}

export default Midias;