import React, { useEffect, useRef } from 'react';

import './styles.css';

import Image from '../Image';
import { lineBreak, getIframeFromString } from '../../useful/helpers';
import { useState } from 'react';

export const NewsBigger = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const newsRef = useRef();

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    let slider = document.querySelector('.sliderRight');
    let height = slider ? slider.offsetHeight : 0;
    height = height + 20;
    newsRef.current.style.minHeight = `calc(100% - ${height}px)`;
  }, [,windowWidth]);

  return (
    <div ref={newsRef} className="newsBoxBigger">
      {props?.hasImage && props.pics.length > 0 &&
        <Image post={props.post} className="newsImg" />
      }
      {props.pics.length === 0 && props?.contents &&
        <div className="multimidiaContent" dangerouslySetInnerHTML={{ __html: props.contents }} />
      }
      <div className="newsContent newsContentBigger hasImage">
      <div className={`borderLeft ${props?.hasBorder ? '' : 'hide'}`}></div>
        <div className="infoBox">
          <ul>
            {props.tags &&
              <>
                {props.tags.length > 0 &&
                  <li><button onClick={() => window.location.href=`/tag/${props.tags[0]}`}>{props.tags[0]}</button></li>
                }
              </>
            }

            {props.cards &&
              <>
                {props.cards.length > 0 &&
                  <li><button onClick={() => window.location.href=`/card/${props.cards[0]}`}>{props.cards[0]}</button></li>
                }
              </>
            }

            {props.date &&
              <li><p>{props.date}</p></li>
            }
          </ul>
        </div>
        <a href={`/noticia/${props.slug}`}>
          <h2 className="newsTitle"><span>{props.title}</span></h2>
        </a>
        {props.desc &&
          <a href={`/noticia/${props.slug}`}>
            <h3 className="newsDesc" dangerouslySetInnerHTML={{ __html: lineBreak(props.desc)}} />
          </a>
        }
      </div>
    </div>
  );
}

export default NewsBigger;